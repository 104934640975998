import React from 'react';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { useAuth } from 'contexts';
import { PAGE_TYPE } from 'enums';
import {
  AwardsPage,
  AwardLanesPage,
  CreateUpdateAwardLanePage,
  CreateUpdateQuotePage,
  CreateUpdateShipperPage,
  CustomersPage,
  DashboardPage,
  FilesPage,
  FuelSheetsPage,
  GlobalMaxBuyRulesPage,
  GlobalRulesPage,
  IntegrationLogsPage,
  LoginPage,
  MaxBuyRulesPage,
  QuoteDetailPage,
  QuoteRulesPage,
  UploadAwardPage,
  UploadWorkingCopyPage,
  WorkingCopiesPage,
} from 'pages';
import {
  AWARD_PERMISSIONS,
  CUSTOMER_PERMISSIONS,
  FILE_PERMISSIONS,
  FUEL_SHEET_PERMISSIONS,
  GLOBAL_RULE_PERMISSIONS,
  INTEGRATION_LOG_PERMISSIONS,
  QUOTE_PERMISSIONS,
  WORKING_COPY_PERMISSIONS,
} from 'permissions';
import { ProtectedRoute } from './ProtectedRoute';

import 'react-toastify/dist/ReactToastify.css';
import { useStyles } from './ReactToastifyOverrides.css';

export const App = () => {
  const classes = useStyles();

  const { currentUser } = useAuth();

  let routes = null;
  if (currentUser) {
    routes = (
      <Switch>
        <Route path='/login'><Redirect to='/quotes' /></Route>
        <ProtectedRoute path='/shippers/:id/fuel-sheets' permissions={[FUEL_SHEET_PERMISSIONS.RETRIEVE]}><FuelSheetsPage/></ProtectedRoute>
        <ProtectedRoute path='/shippers/:id/max-buy-rules' permissions={[CUSTOMER_PERMISSIONS.MAX_BUY_RULES_RETRIEVE]}><MaxBuyRulesPage/></ProtectedRoute>
        <ProtectedRoute path='/shippers/:id/price-rules' permissions={[CUSTOMER_PERMISSIONS.PRICE_RULES_RETRIEVE]}><QuoteRulesPage/></ProtectedRoute>
        <ProtectedRoute path='/shippers/create' permissions={[CUSTOMER_PERMISSIONS.RETRIEVE]}><CreateUpdateShipperPage type={PAGE_TYPE.CREATE}/></ProtectedRoute>
        <ProtectedRoute path='/shippers/edit/:id' permissions={[QUOTE_PERMISSIONS.RETRIEVE]}><CreateUpdateShipperPage type={PAGE_TYPE.EDIT}/></ProtectedRoute>
        <ProtectedRoute path='/shippers' permissions={[CUSTOMER_PERMISSIONS.RETRIEVE]}><CustomersPage/></ProtectedRoute>
        <ProtectedRoute path='/quotes/create' permissions={[QUOTE_PERMISSIONS.RETRIEVE]}><CreateUpdateQuotePage type={PAGE_TYPE.CREATE}/></ProtectedRoute>
        <ProtectedRoute path='/quotes/:id/edit' permissions={[QUOTE_PERMISSIONS.RETRIEVE]}><CreateUpdateQuotePage type={PAGE_TYPE.EDIT}/></ProtectedRoute>
        <ProtectedRoute path='/quotes/:id' permissions={[QUOTE_PERMISSIONS.RETRIEVE]}><QuoteDetailPage/></ProtectedRoute>
        <ProtectedRoute path='/integration-logs' permissions={[INTEGRATION_LOG_PERMISSIONS.RETRIEVE]}><IntegrationLogsPage/></ProtectedRoute>
        <ProtectedRoute path='/working-copies/upload' permissions={[WORKING_COPY_PERMISSIONS.RETRIEVE]}><UploadWorkingCopyPage type={PAGE_TYPE.CREATE}/></ProtectedRoute>
        <ProtectedRoute path='/working-copies/:id/edit' permissions={[WORKING_COPY_PERMISSIONS.RETRIEVE]}><UploadWorkingCopyPage type={PAGE_TYPE.EDIT}/></ProtectedRoute>
        <ProtectedRoute path='/working-copies' permissions={[WORKING_COPY_PERMISSIONS.RETRIEVE]}><WorkingCopiesPage/></ProtectedRoute>
        <ProtectedRoute path='/price-rules' permissions={[GLOBAL_RULE_PERMISSIONS.PRICE_RULES_RETRIEVE]}><GlobalRulesPage/></ProtectedRoute>
        <ProtectedRoute path='/max-buy-rules' permissions={[GLOBAL_RULE_PERMISSIONS.MAX_BUY_RULES_RETRIEVE]}><GlobalMaxBuyRulesPage/></ProtectedRoute>
        <ProtectedRoute path='/awards/upload' permissions={[AWARD_PERMISSIONS.CREATE]}><UploadAwardPage type={PAGE_TYPE.CREATE}/></ProtectedRoute>
        <ProtectedRoute path='/awards/:id/edit' permissions={[AWARD_PERMISSIONS.UPDATE]}><UploadAwardPage type={PAGE_TYPE.EDIT}/></ProtectedRoute>
        <ProtectedRoute path='/awards/:awardId/lanes/create' permissions={[AWARD_PERMISSIONS.ADD_AWARD_LANE_TO_AWARD]}><CreateUpdateAwardLanePage type={PAGE_TYPE.CREATE}/></ProtectedRoute>
        <ProtectedRoute path='/awards/:awardId/lanes/:laneId/edit' permissions={[AWARD_PERMISSIONS.UPDATE_AWARD_LANE]}><CreateUpdateAwardLanePage type={PAGE_TYPE.EDIT}/></ProtectedRoute>
        <ProtectedRoute path='/awards/:id/lanes' permissions={[AWARD_PERMISSIONS.LIST_AWARD_LANES]}><AwardLanesPage/></ProtectedRoute>
        <ProtectedRoute path='/awards' permissions={[AWARD_PERMISSIONS.RETRIEVE]}><AwardsPage/></ProtectedRoute>
        <ProtectedRoute path='/files' permissions={[FILE_PERMISSIONS.RETRIEVE]}><FilesPage/></ProtectedRoute>
        <Route path='*' component={DashboardPage} ></Route>
        <ProtectedRoute path='/quotes' permissions={[QUOTE_PERMISSIONS.RETRIEVE]}><DashboardPage/></ProtectedRoute>
        <Redirect to='/quotes' />
      </Switch>
    );
  } else {
    routes = (
      <Switch>
        <Route restricted={true} exact path='/login' component={LoginPage}/>
        <Redirect to='/login' />
      </Switch>
    );
  }

  return (
    <>
      <Router>
        {routes}
      </Router>
      <ToastContainer autoClose={3000} hideProgressBar className={classes.toastifyToastContainer} />
    </>
  );
};
